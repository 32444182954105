import React, { useEffect } from "react";
import Awaiting from "../../component/common/Awaiting";
import { useLocation, useNavigate } from "react-router-dom";
import RootLayout from "../../component/layout";

const PaymentProcessed = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const payment = location.state;

  useEffect(() => {
    if (!payment) {
      navigate("/error/config");
    }
  }, [payment]);

  return (
    <RootLayout>
      <Awaiting
        imageSource="/payment-processed.png"
        title="Payment Already Processed"
        description="Your payment has already been processed. Thank you!"
        backText="Back To Site"
        onBackClick={() =>
          (window.location.href = `${payment?.successCallback}?key=${payment?.keyUsed}&paymentId=${payment?._id}`)
        }
      />
    </RootLayout>
  )
}

export default PaymentProcessed;
