import React, { useState, useEffect } from "react";
import { Button } from "antd";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import accounting from "accounting";
import getSymbolFromCurrency from "currency-symbol-map";
import { processCheckout } from "../../network/api";

const userDetails = {
  cardNumber: "",
  cardName: "",
  expiryDate: "",
  cvv: "",
  country: "",
};

const SumupCard = ({ apiKey, paymentId, payment }) => {
  const navigate = useNavigate();

  const [seconds, setSeconds] = useState(600);
  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useState("dark");

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;


  useEffect(() => {
    if (!payment) {
      navigate("/error/config");
      return;
    }

    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(interval);
          navigate("/error/timeout", { state: payment });
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [payment, navigate]);

  const [validationErrors, setValidationErrors] = useState({});
  const [user, setUser] = useState(userDetails);
  const validateFields = () => {
    const errors = {};
    if (user.cardNumber === "") {
      errors.cardNumber = "Please enter your card number";
    }
    if (user.cardName === "") {
      errors.cardName = "Please enter your card name";
    }
    if (user.expiryDate === "") {
      errors.expiryDate = "Please enter your expiry date / CVV";
    }
    if (user.cardCountry === "") {
      errors.cardCountry = "Please enter your country";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const removeSpaces = (cardNumber) => {
    return cardNumber.replace(/\s+/g, "");
  };

  const getMonthAndYear = (dateString) => {
    const [month, year] = dateString
      .split(" / ")
      .map((item) => parseInt(item, 10));
    return { month, year };
  };

  const handleUserDetails = (fieldName, value) => {
    // Check if the field being updated is 'cardNumber'
    if (fieldName === "cardNumber") {
      // Remove any non-digit characters
      const digitsOnly = value.replace(/\D/g, "");

      // Add a space after every 4 digits
      value = digitsOnly.replace(/(.{4})/g, "$1 ").trim();
    } else if (fieldName === "expiryDate") {
      // Remove any non-digit characters except for '/'
      const digitsOnly = value.replace(/[^\d]/g, "");

      // Format the value as MM / YY
      if (digitsOnly.length <= 2) {
        value = digitsOnly; // Only month entered, no formatting needed yet
      } else {
        value = `${digitsOnly.slice(0, 2)} / ${digitsOnly.slice(2, 4)}`;
      }
    }
    setUser({
      ...user,
      [fieldName]: value,
    });
  };

  const handleSubmit = async (event) => {
    try {
    event.preventDefault();
    setLoading(true);
    if (!validateFields()) {
      return;
    }
    const { cardNumber, cardName, expiryDate, cvv } = user;
    const { year: expiryYear, month: expiryMonth } =
      getMonthAndYear(expiryDate);
    const paymentPayload = {
      name: cardName,
      cardNumber: removeSpaces(cardNumber),
      expiryMonth,
      expiryYear,
      cvv,
      paymentId,
    };
    const checkout = await processCheckout(apiKey, paymentPayload);
    if(!checkout.url){
      throw new Error("Payment failed")
    }
    window.location.assign(checkout.url + `?key=${apiKey}&paymentId=${paymentId}`);
  } catch (error) {
    window.location.assign(payment.successCallback + `?key=${apiKey}&paymentId=${paymentId}`);
  }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full flex flex-col items-center justify-start"
    >
      <div
        className={
          seconds > 10
            ? "timer bg-orange header-right-col"
            : "timer bg-red header-right-col"
        }
      >
        Expires In: {minutes.toString().padStart(2, "0")}:
        {remainingSeconds.toString().padStart(2, "0")}
      </div>
      
      <div className="main-top-checkout-section-box flex flex-col items-center mb-[25px]">
        <div className="table-wrapper summary-maintable w-full checkout-summary-table">
          <table className="table w-full">
            <tbody>
              <tr>
                <td className="common-lbl">Date/Time</td>
                <td className="common-lbl text-right">
                  {moment(payment.createdAt).format("LLL")}
                </td>
              </tr>
              <tr>
                <td className="common-lbl">
                  <p className="flex items-center justify-start">
                    Amount{" "}
                    <span className="small-text-lbl ml-1">
                      ({payment?.currency?.toUpperCase()})
                    </span>
                  </p>
                </td>
                <td className="common-lbl text-right">
                  {accounting.formatMoney(payment?.amount, {
                    symbol: getSymbolFromCurrency(
                      payment?.currency?.toUpperCase()
                    ),
                    format: "%s%v",
                  })}
                </td>
              </tr>
             
              
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-full mb-[110px]">
        <div className="w-full flex flex-col items-center justify-start">
          <h3 className="w-full common-lbl mb-[15px]">Card Details</h3>
          <div className="w-full bnksy-input-group mb-[20px]">
            <input
              type="text"
              className="bnksy-form-input"
              id="signup-name"
              value={user.cardName}
              placeholder="Card Name"
              onChange={(e) => handleUserDetails("cardName", e.target.value)}
              required
            />
            {validationErrors.cardName && (
              <p className="text-red-600 font-small mt-1">
                {validationErrors.cardName}
              </p>
            )}
          </div>
          <div className="w-full bnksy-input-group mb-[20px]">
            <input
              type="text"
              maxLength={19}
              className="bnksy-form-input"
              id="signup-name"
              value={user.cardNumber}
              placeholder="Card Number"
              onChange={(e) => handleUserDetails("cardNumber", e.target.value)}
              required
            />
            <div className="absolute calendar-icon-box right-[15px] z-0">
              <img src="/cc-icons.png" alt="logo" className="w-28" />
            </div>
            {validationErrors.cardNumberar && (
              <p className="text-red-600 font-small mt-1">
                {validationErrors.cardNumberar}
              </p>
            )}
          </div>
          <div className="w-full flex items-center mb-[20px]">
            <div className="w-full flex flex-col items-center">
              <div className="w-full flex items-center">
                <div className="w-7/12 bnksy-input-group pr-2">
                  <input
                    id="card-date"
                    type="text"
                    className="bnksy-form-input"
                    placeholder="MM / YY"
                    value={user.expiryDate}
                    onChange={(e) =>
                      handleUserDetails("expiryDate", e.target.value)
                    }
                  />
                </div>
                <div className="w-5/12 bnksy-input-group pl-2">
                  <input
                    type="password"
                    maxLength={4}
                    className="bnksy-form-input"
                    id="card-cvv"
                    value={user.cvv}
                    placeholder="CVC"
                    onChange={(e) => handleUserDetails("cvv", e.target.value)}
                    required
                  />
                  <div className="absolute calendar-icon-box right-[15px] z-0">
                    <img
                      src="/cvc-bk.svg"
                      alt="logo"
                      className="w-full logo-transparent-white"
                    />
                  </div>
                </div>
              </div>
              {validationErrors.expiryDate && (
                <p className="w-full text-red-600 font-small mt-1">
                  {validationErrors.expiryDate}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
        <Button
          type="primary"
          htmlType="submit"
          className={`bnksy-btn-primary`}
          loading={loading}
        >
          Pay
          {accounting.formatMoney(payment?.amount, {
            symbol: getSymbolFromCurrency(payment?.currency?.toUpperCase()),
            format: " %s%v",
          })}
        </Button>
      </div>
    </form>
  );
};

export default SumupCard;
