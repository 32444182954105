import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RootLayout from "../component/layout";
import { getPayment } from "../network/api";
import { toast } from "react-toastify";
import { verifyToken } from "../utils/utils";
import { setCheckoutConfig, setCustomerData } from "../redux/actions/actions";
import { useDispatch } from "react-redux";
import { customerDetails } from "../redux/actions/customerActions";
import Cookies from "js-cookie";
import Awaiting from "../component/common/Awaiting";

const Index = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [theme, setTheme] = useState("dark");

  const checkStatus = async () => {
    const response = await dispatch(customerDetails());
    return response.data.kycStatus;
  };

  useEffect(() => {
    const fetchData = async () => {
      const searchObject = {};
      const searchParams = new URLSearchParams(location.search);
      for (const [key, value] of searchParams.entries()) {
        searchObject[key] = value;
      }
      if (searchObject.hasOwnProperty("theme")) {
        setTheme(searchObject.theme);
      }
      if (
        !searchObject.hasOwnProperty("key") ||
        !searchObject.hasOwnProperty("paymentId")
      ) {
        navigate("/error/config");
      } else {
        const payment = await getPayment(
          searchObject?.key,
          searchObject?.paymentId
        );
        if (payment?.status === "awaiting") {

          const createdAt = new Date(payment.createdAt).toISOString();
          const nowUTC = new Date().toISOString();
          
          const paymentTimestamp = Date.parse(createdAt);
          const currentTimestamp = Date.parse(nowUTC);
          
          const diffInSeconds = Math.floor((currentTimestamp - paymentTimestamp) / 1000);
          
          if (diffInSeconds > 600) { // 600 seconds = 10 minutes
            navigate("/error/timeout", { state: payment });
            return;
          }


          await dispatch(setCheckoutConfig(payment));
          if (payment?.customerId) {
            navigate("/checkout", { state: { ...payment, theme } });
          } else {
            if (!Cookies.get("customer_access_token")) {
              navigate("/auth/signin");
            } else {
              try {
                const verify_customer_token = verifyToken(
                  Cookies.get("customer_access_token")
                );

                await dispatch(
                  setCustomerData({
                    to: "",
                    customerID: verify_customer_token.sub,
                  })
                );

                const status = await checkStatus();

                if (status === "approved") {
                  navigate("/checkout", { state: { ...payment, theme } });
                } else if (
                  status === "not-initiated" ||
                  status === "created" ||
                  status === "cancelled"
                ) {
                  navigate("/auth/kyc");
                } else {
                  navigate("/auth/kyc/status");
                }
              } catch (error) {
                toast.error(error.message);
                navigate("/error/config");
              }
            }
          }
        } else {
          navigate("/info/payment-processed", { state: { ...payment } });
        }
      }
    };
    fetchData();
  }, [location.search, navigate, theme]);

  useEffect(() => {
    // Set attributes on the body tag
    document.body.setAttribute("data-bs-theme", theme);

    // Clean up function to remove attributes when the component unmounts
    return () => {
      document.body.setAttribute("data-bs-theme", theme);
    };
  }, [theme]);

  return (
    <RootLayout>
      <Awaiting />
    </RootLayout>
  );
};

export default Index;
