import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RootLayout from "../../../component/layout";
import { verifyPayment } from "../../../network/api";
import Awaiting from "../../../component/common/Awaiting";

const PegasusSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentObj, setPaymentObj] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const searchObject = {};
      const searchParams = new URLSearchParams(location.search);
      for (const [key, value] of searchParams.entries()) {
        searchObject[key] = value;
      }
      if (
        !searchObject.hasOwnProperty("key") ||
        !searchObject.hasOwnProperty("paymentId")
      ) {
        navigate("/error/config");
      } else {
        const status = await verifyPayment(
          searchObject.key,
          searchObject.paymentId
        );
        setPaymentObj(status);
        if (status?.status === "success") {
          window.location.assign(
            status.successCallback + "?paymentId=" + status._id
          );
        } else {
          window.location.assign(
            status.failureCallback + "?paymentId=" + status._id
          );
        }
      }
    };
    fetchData();
  }, [location.search, navigate]);
  return (
    <RootLayout>
      {paymentObj && (
        <Awaiting
          imageSource="/Please-Wait.png"
          title="Payment in progress"
          description={
          <>
            Your payment is under process. It will be verified soon. It can take
            up to <strong>20 minutes</strong>. You can also go back to the site
            and your points will be automatically added.
          </>
        }
        backText="Back To Site"
        onBackClick={() =>
          (window.location.href = `${paymentObj?.successCallback}?key=${paymentObj?.keyUsed}&paymentId=${paymentObj?._id}`)
          }
        />
      )}
    </RootLayout>
  );
};

export default PegasusSuccess;
